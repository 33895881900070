<script type='ts' setup>
import { useAclStore } from '../store/acl';
import Footer from '~/components/layouts/footer.vue'
import Nav from '~/components/layouts/nav.vue'

const $acl = useAclStore()
const fbID = 'tODO'

onMounted(() => {
  $acl.checkSession()
})
</script>

<template>
  <a-layout>
    <slot />
  </a-layout>
</template>

<style scoped>

</style>
